.theme-base-08 {
	.sidebar {
		background-color: #ac4142;
	}
	.content a {
		color: #ac4142;
	}
	.related-posts li a:hover {
		color: #ac4142;
	}
}
.theme-base-09 {
	.sidebar {
		background-color: #d28445;
	}
	.content a {
		color: #d28445;
	}
	.related-posts li a:hover {
		color: #d28445;
	}
}
.theme-base-0a {
	.sidebar {
		background-color: #f4bf75;
	}
	.content a {
		color: #f4bf75;
	}
	.related-posts li a:hover {
		color: #f4bf75;
	}
}
.theme-base-0b {
	.sidebar {
		background-color: #90a959;
	}
	.content a {
		color: #90a959;
	}
	.related-posts li a:hover {
		color: #90a959;
	}
}
.theme-base-0c {
	.sidebar {
		background-color: #75b5aa;
	}
	.content a {
		color: #75b5aa;
	}
	.related-posts li a:hover {
		color: #75b5aa;
	}
}
.theme-base-0d {
	.sidebar {
		background-color: #6a9fb5;
	}
	.content a {
		color: #6a9fb5;
	}
	.related-posts li a:hover {
		color: #6a9fb5;
	}
}
.theme-base-0e {
	.sidebar {
		background-color: #aa759f;
	}
	.content a {
		color: #aa759f;
	}
	.related-posts li a:hover {
		color: #aa759f;
	}
}
.theme-base-0f {
	.sidebar {
		background-color: #8f5536;
	}
	.content a {
		color: #8f5536;
	}
	.related-posts li a:hover {
		color: #8f5536;
	}
}

.theme-base-01 {
    .sidebar {
            background-color: #373E43;
    }
    .content a {
            color: #375EAB;
    }
    .related-posts li a:hover {
            color: #373E43;
    }
    .posts li a {
            color: #375EAB;
            font-weight: bold;
    }
    .post-list__item a {
            color: #000000;
    }
    .project__title a {
            color: #000000;
    }
}